.sc-ion-label-md-h.sc-ion-label-md-s.ion-color.md.hydrated {
  opacity: 1;
}

.mysteryLycanDiv {
  font-style: italic;
  padding-bottom: 10px;
}

.roleAlert .alert-sub-title {
  white-space: pre-wrap;
}

.RolesComponent__ArrowIconDown::part(detail-icon) {
  transform: rotate(90deg);
}

.RolesComponent__ArrowIconUp::part(detail-icon) {
  transform: rotate(-90deg);
}

::part(detail-icon) {
  transition: transform 0.25s;
}

.icon-large {
  vertical-align: middle;
}
