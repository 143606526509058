.LoginScreen__image {
  width: 80%;
  margin: 0 auto;
  padding-top: 50px;
  max-width: 350px;
}

.LoginScreen__title {
  margin: 0 auto;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
}

.LoginScreen__button {
  margin: 0 auto;
}

.LoginScreen__name-input {
  text-align: center;
  font-size: 18px;
}

.LoginScreen__warning {
  text-align: center;
}

.LoginScreen__label {
  text-align: center;
  display: block;
  margin-top: 6px;
}

.LoginScreen__root {
  max-width: 740px;
  max-height: 750px;
  margin: 0 auto;
}
